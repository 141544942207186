.container {
    display: flex;
    height: 100vh;     
    width: 100vw;     
    background-color: #f5f7fa;
    background-image: linear-gradient(to bottom right, #f5f7fa, #e1e7ec);
    align-items: center; 
    justify-content: center;  
    max-width: 1800px

}


.login-side {
    flex: 1;
    position: relative;
}

.background-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.logo {
    width: 120px; 
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-box {
    flex: 1;
    background: rgba(255, 255, 255, 0.95); 
    padding: 60px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    align-self: center; 
    width: 300px;  
    margin-left: 50px;
    margin-right: 50px;
    width: 350px; 

}

h1 {
    color: #333;
    font-size: 31px;
    margin-bottom: 10px;
}

p {
    color: #666;
    font-size: 19px;
    margin-bottom: 30px;
}

.login-button {
    padding: 14px 20px; 
    background-color: #007BFF;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 50%;
    margin-left: auto;
    margin-right: auto;

}

.login-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}
