.chat-window {
    overflow-y: auto;
    height: 95vh;
}

.history-item {
    background-color: white;
    transition: transform 0.3s ease-in-out;
}

.history-item:hover {
    transform: scale(1.03);
}
.history-item-not-question {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Adjust the color and style as needed */
}
